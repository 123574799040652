<!--
 * @Author: huwanjun
 * @Date: 2021-04-07 11:01:12
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-19 12:27:09
 * @Description: 客户管理
-->

<template>
  <NeoPageLayout>
    <div class="customer pt-6 ml-4 mr-4">
      <h2 class="title">{{ $t('customer.title') }}</h2>
      <!-- search -->
      <div class="search">
        <a-input class="search-input" v-model="searchInput" :placeholder="$t('customer.search_holder')"></a-input>
        <a-button type="primary" class="search-btn" @click="onSearch">{{ $t('search') }}</a-button>
      </div>
      <!-- 添加客户 -->
      <a-button type="primary" class="mt-4" @click="addOrEditCompany('')" v-if="$role('customer_new_company')">{{
        $t('customer.add_company')
      }}</a-button>
      <!-- 新增客户抽屉 -->
      <AddCustomerInfo :visible.sync="visible" :id="cusInfoId" @submitCallback="getCusAgencyList"></AddCustomerInfo>
      <!-- table -->
      <div class="table">
        <a-table
          class="striped no-border"
          rowKey="id"
          :data-source="dataSource"
          :pagination="pagination"
          :loading="loading"
        >
          <a-table-column :title="$t('customer.taxIdNumber')" key="code" width="50px">
            <template slot-scope="text">
              {{ text.taxIdNumber }}
            </template>
          </a-table-column>
          <a-table-column :title="$t('customer.name')" key="name" width="150px">
            <template slot-scope="text">
              {{ text.name }}
            </template>
          </a-table-column>
          <a-table-column :title="$t('customer.status')" key="status" width="50px">
            <template slot-scope="text">
              <span :class="text.status === 0 ? 'redfont' : ''">
                {{ localeDict.customerStatus[Math.min(2, text.status)] | defined }}
              </span>
            </template>
          </a-table-column>
          <!-- <a-table-column :title="$t('customer.Pline_name')" key="productLineNames" :ellipsis="true" width="500px">
            <template slot-scope="text">
              <span
                v-if="text.productLineNames"
                :title="
                  text.productLineNames
                    .split(',')
                    .filter((item) => item)
                    .join('、')
                "
                >{{
                  text.productLineNames
                    .split(',')
                    .filter((item) => item)
                    .join('、')
                }}</span
              >
            </template>
          </a-table-column> -->
          <a-table-column :title="$t('customer.topManager')" key="supplierSpmName" width="80px">
            <template slot-scope="text">
              {{ text.topManagerName }}
            </template>
          </a-table-column>
          <a-table-column :title="$t('customer.join_time')" key="createDate" width="80px">
            <template slot-scope="text">
              {{ text.createDate | dateFormat }}
            </template>
          </a-table-column>
          <!-- 查看详情 -->
          <a-table-column :title="$t('customer.action')" key="action" width="50px">
            <template slot-scope="record">
              <a @click="addOrEditCompany(record.id)">{{ $t('customer.update') }}</a>
            </template>
          </a-table-column>
        </a-table>
      </div>
    </div>
  </NeoPageLayout>
</template>

<script>
import { mapState } from 'vuex'
import AddCustomerInfo from './compoment/AddCustomerInfo.vue'

export default {
  name: 'customer',
  components: { AddCustomerInfo },
  data() {
    return {
      dataSource: [],
      searchInput: '',
      loading: false,
      visible: false,
      pagination: {
        size: 'middle',
        total: 0,
        pageSize: 20,
        current: 1,
        pageSizeOptions: ['20', '50'],
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) => {
          this.pagination.current = 1
          this.pagination.pageSize = pageSize
          this.getCusAgencyList()
        },
        onChange: (page) => {
          this.pagination.current = page
          this.getCusAgencyList()
        },
      },
      localeDict: window.$g.localeDict,
      cusInfoId: '',
    }
  },
  computed: {
    ...mapState('app', {
      userInfo: (state) => state.userInfo,
    }),
  },
  mounted() {
    this.getCusAgencyList()
  },
  methods: {
    // 获取客户信息列表
    async getCusAgencyList() {
      try {
        this.loading = true
        const data = await this.$http('cusInfoList', {
          limit: this.pagination.pageSize,
          page: this.pagination.current,
          cusEntityInfo: this.searchInput,
        })
        this.loading = false
        this.dataSource = data.list
        this.pagination.total = data.total
      } catch (err) {
        this.loading = false
        this.$httpNotify(err)
      }
    },
    // 查看详情
    checkDetails(id, value) {
      // this.$router.push({ name: 'agency_agencyInfo', query: { id: id, extraName: value } })
      console.log(id, value)
    },
    // 新增、编辑公司
    async addOrEditCompany(id) {
      // this.$router.push({ name: 'agency_newAgency' })
      this.cusInfoId = id

      this.visible = true
    },
    // 搜索按钮
    onSearch() {
      this.pagination.current = 1
      this.getCusAgencyList()
    },
  },
}
</script>

<style lang="less" scoped>
.customer {
  .title {
    font-size: @font_size_6;
    font-weight: bold;
    color: @text_color_1;
  }
  .search {
    .search-input {
      width: 366px;
      margin-right: 10px;
    }
    .search-btn {
      min-width: 82px;
    }
  }
  .table {
    margin-top: 8px;
  }
}
::v-deep .ant-table-body {
  .ant-table-tbody {
    .redfont {
      color: #f56c6c;
    }
  }
}
</style>
